import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
export const IMG_URL = process.env.REACT_APP_IMAGE_URL;

class Navbar extends Component {
  state = {
    user: null,
    img: true,
  };
  toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  toggleRightSidebar() {
    document.querySelector(".right-sidebar").classList.toggle("open");
  }
  fixedHeader() {
    window.onload = function () {
      window.onscroll = function () {
        const header = document.querySelector("#header");
        if (header) {
          const headerHeight =
            header.querySelector(".header-wrapper").offsetHeight;
          if (window.pageYOffset > headerHeight) {
            header.classList.add("sticky");
            header.style.height = headerHeight + "px";
          } else {
            header.classList.remove("sticky");
            header.style.height = "";
          }
        }
      };
    };
  }
  subMenu() {
    const dropdown = document.querySelectorAll(".main-menu .dropdown a");
    dropdown.forEach((item) => {
      item.onclick = function (e) {
        e.preventDefault();
        if (window.innerWidth < 1200) {
          const parent = this.parentElement;
          const next = this.nextElementSibling;

          if (!parent.classList.contains("active")) {
            parent.classList.add("active");
            next.style.display = "block";
            next.style.maxHeight = "";
            let height = next.offsetHeight;
            next.style.maxHeight = 0;
            setTimeout(() => {
              next.style.maxHeight = height + "px";
            }, 10);
          } else {
            parent.classList.remove("active");
            next.style.maxHeight = 0;
            setTimeout(() => {
              next.style.display = "none";
            }, 300);
          }
        }
      };
    });
    window.onresize = function () {
      if (window.innerWidth >= 1200) {
        const dropdown = document.querySelectorAll(".main-menu .dropdown");
        dropdown.forEach((item) => item.classList.remove("active"));
        const submenu = document.querySelectorAll(".main-menu .dropdown ul");
        submenu.forEach((item) => item.removeAttribute("style"));
      }
    };
  }
  componentDidMount() {
    this.fixedHeader();
    // this.toggleMenu/();
    this.subMenu();
    // const userData = JSON.parse(localStorage.getItem('user'));
    // if (userData) this.setState({ user: userData });
    const userData = JSON.parse(localStorage.getItem("user"));
    if (userData) this.setState({ user: userData });

    if (userData) {
      if (userData.profile_img_path === null) {
        this.setState({ img: true });
      } else {
        this.setState({ img: false });
      }
    }
  }

  toggleMenu() {
    const hamburgers = document.querySelectorAll(".hamburger-wrapper");
    hamburgers.forEach(
      (item) =>
      (item.onclick = function () {
        document.querySelector(".user-view").classList.toggle("open-menu");
      })
    );
    const overlay = document.querySelector(".overlay");
    overlay.onclick = function () {
      document.querySelector(".user-view").classList.remove("open-menu");
    };
  }

  handleLogout(e) {
    e.preventDefault();
    const role_id = JSON.parse(localStorage.getItem("user")).role_id;
    localStorage.removeItem("user");
    localStorage.removeItem("corporate_id");
    localStorage.removeItem("token");

    console.log("role_id-->", role_id);
    if (role_id === 6) {
      window.location = "/user-pages/corporate/login";
    } else if (role_id === 7) {
      window.location = "/user-pages/employee/login";
    } else {
      window.location = "/";
    }
  }
  render() {
    return (
      <div>
        <header id="header">
          <div className="header-wrapper">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-auto">
                  <a href="/corporate/dashboard" className="logo">
                    <img
                      src={require("../../assets/images/user/logo.png")}
                      alt=""
                      width="190"
                    />
                  </a>
                </div>
                
                {/* <button
                  className="navbar-toggler navbar-toggler align-self-center"
                  type="button"
                  onClick={() => document.body.classList.toggle("sidebar-icon-only")}
                >
                  <span className="mdi mdi-menu"></span>
                </button> */}

                <div className="ml-auto col-auto">
                  <div className="navbar p-0">
                    <div className="navbar-menu-wrapper d-flex align-items-stretch">
                      <ul className="navbar-nav navbar-nav-right">
                        {/* <li className="nav-item">
                          <div className="hamburger-wrapper">
                            <div className="hamburger">
                              <span />
                              <span />
                              <span />
                            </div>
                          </div>
                          <div className="overlay"></div>
                        </li> */}
                        {this.state.user ? (
                          <>
                            {(this.state.user.role_id = 6) ? ("") : (
                              <li className="nav-item nav-profile">
                                <Dropdown alignRight>
                                  <Dropdown.Toggle className="nav-link mr-0">
                                    <div className="nav-profile-img">
                                      {this.state.user.logo_path || this.state.user.logo_path == null || this.state.user.logo_path == undefined ? (
                                        <img
                                          src={require("../../assets/images/faces/facef.png")}
                                          alt="user"
                                        />
                                      ) : (
                                        <img
                                          src={IMG_URL + this.state.user.logo_path}
                                          alt="user"
                                        />
                                      )}
                                      <span className="availability-status online"></span>
                                    </div>
                                    <div className="nav-profile-text">
                                      <p className="mb-1 text-black">
                                        <Trans>{this.state.user.name}</Trans>
                                      </p>
                                    </div>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="navbar-dropdown">
                                    <Dropdown.Item
                                      href="!#"
                                      onClick={this.handleLogout}
                                    >
                                      <i className="mdi mdi-logout mr-2 text-primary"></i>
                                      <Trans>Signout</Trans>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </li>)
                            }
                          </>
                        ) : (
                          <li className="nav-item">
                            <Link
                              to={{
                                pathname: "/user-pages/login",
                                state: { prevPath: true },
                              }}
                              className="btn btn-primary cst-btn mr-0"
                            >
                              Login
                            </Link>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default Navbar;
