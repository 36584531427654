import React, { Component, lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ReservationLogs from "./EmployeeReservation/ReservationLogs";
import Spinner from "./Shared/Spinner";

const Login = lazy(() => import("./User-Pages/Login"));
const Forgot = lazy(() => import("./User-Pages/Forgot"));
const PasswordReset = lazy(() => import("./User-Pages/PasswordReset"));
const EmployeesDetails = lazy(() => import("./Containers/EmployeesDetails"));
const BookingDetails = lazy(() => import("./Containers/BookingDetails"));

const Dashboard = lazy(() => import("./Containers/Dashboard"));
const Employees = lazy(() => import("./Containers/Employees"));
const Addemployees = lazy(() => import("./Containers/AddEmployees"));
const CorporateEmployees = lazy(() =>
  import("./Containers/CorporateEmployees")
);
const CorporateUploadDocuments = lazy(() =>
  import("./Containers/CorporateUploadDocuments")
);

const ReservationDetails = lazy(() =>
  import("./EmployeeReservation/ReservationDetails")
);

const Helpdesk = lazy(() => import("./Helpdesk/HelpDesk"));

const CorporateEmpProperties = lazy(() =>
  import("./Containers/CorporateEmpProperties")
);

const OccupancyReport = lazy(() => import("./reports/occupancyreport"));
const OccupancyReportDetails = lazy(() =>
  import("./reports/occupancyreportdetails")
);

const CorporateDepartments = lazy(() =>
  import("./Containers/CorporateDepartments")
);
const Property = lazy(() => import("./Containers/Property"));
const Linkproperty = lazy(() => import("./Containers/Linkproperty"));
const MappedProperty = lazy(() => import("./Containers/MappedProperty"));
const MappedPropertyDetails = lazy(() =>
  import("./Containers/MappedPropertyDetails")
);

//property
const AddCProperty = lazy(() => import("./Containers/AddProperty"));
const PropertyCList = lazy(() => import("./Containers/PropertyList"));
const PropertyCDetails = lazy(() => import("./Containers/PropertyDetails"));

//Provider
const AddCProvider = lazy(() => import("./Containers/AddProvider"));
const PendingCBookingList = lazy(() => import("./Containers/Booking-List"));
const CorporateBookingList = lazy(() =>
  import("./Containers/CorporateBookingList")
);

//access component
const AddCAccess = lazy(() => import("./Containers/AddAccess"));
const EditCAccess = lazy(() => import("./Containers/EditAccess"));
const AccessCList = lazy(() => import("./Containers/AccessList"));
const AccessCRight = lazy(() => import("./Containers/AccessRights"));

//locations
const CLocation = lazy(() => import("./Containers/AddLocation"));

//Management pages
const CManagement = lazy(() => import("./Containers/Management"));

//Corporate pages
const AddCCorporate = lazy(() => import("./Containers/AddCorporate"));

//Corporate pages
const SeatReservation = lazy(() => import("./Containers/SeatReservation"));

const SeatReservationHistory = lazy(() =>
  import("./Containers/SeatReservationHistory")
);

class AppRoutes extends Component {
  validurl = true;

  state = {
    url: "/user-pages/corporate/login",
    userData: "",
  };

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    this.setState({ userData: user });
    console.log(user);

    if (
      window.location.pathname === "/reservation-history" &&
      this.state.userData.role_id === 6
    ) {
      this.setState({ url: "/user-pages/employee/login" });
    }

    if (
      window.location.pathname !== "/reservation-history" &&
      this.state.userData.role_id === 7
    ) {
      this.setState({ url: "/reservation-history" });
    }

    //   if (userData) {
    //     this.validurl = true;
    //     if (userData.role_id === 6) {
    //       window.location.href = "/corporate/dashboard";
    //       // this.setState({url: '/corporate/dashboard'});
    //     } else {
    //       this.setState({url: '/discover'});
    //     }
    //   } else {
    //     this.setState({url: '/discover'});
    //     this.validurl = false;
    //   }
  }

  render() {
    const user = JSON.parse(localStorage.getItem("user"));

    return (
      <Suspense fallback={<Spinner />}>
        {!localStorage.getItem("token") ? (
          <Switch>
            <Route exact path="/user-pages/corporate/login" component={Login} />
            <Route exact path="/user-pages/employee/login" component={Login} />
            <Route exact path="/forgot-password" component={Forgot} />
            <Route exact path="/reset-password" component={PasswordReset} />
            <Redirect to="/user-pages/corporate/login" />
          </Switch>
        ) : (
          <>
            {user && user.role_id === 6 ? (
              <Switch>
                <Route path="/corporate/dashboard" component={Dashboard} />

                <Route path="/user-management" component={CManagement} />
                <Route path="/new-corporate" component={AddCCorporate} />
                <Route exact path="/locations" component={CLocation} />

                <Route exact path="/access-add" component={AddCAccess} />
                <Route exact path="/access-edit/:id" component={EditCAccess} />
                <Route exact path="/access-list" component={AccessCList} />
                <Route exact path="/access-rights" component={AccessCRight} />

                <Route
                  exact
                  path="/booking-details/:id"
                  component={BookingDetails}
                />

                <Route exact path="/provider-add" component={AddCProvider} />
                <Route
                  exact
                  path="/pending-bookings"
                  component={PendingCBookingList}
                />
                <Route
                  exact
                  path="/booking-list"
                  component={CorporateBookingList}
                />

                <Route exact path="/home" component={PropertyCList} />
                <Route exact path="/property-add" component={AddCProperty} />
                <Route exact path="/link-property" component={Linkproperty} />

                <Route
                  exact
                  path="/occupancy-report"
                  component={OccupancyReport}
                />
                <Route
                  exact
                  path="/occupancy-report-details/:id"
                  component={OccupancyReportDetails}
                />

                <Route
                  exact
                  path="/home/property/:id"
                  component={PropertyCDetails}
                />

                <Route
                  exact
                  path="/documents"
                  component={CorporateUploadDocuments}
                />

                <Route exact path="/employees" component={Employees} />
                <Route exact path="/add-employee" component={Addemployees} />

                <Route
                  exact
                  path="/corporate-employees/:id"
                  component={CorporateEmployees}
                />

                <Route
                  exact
                  path="/corporate-emp-poperties/:id"
                  component={CorporateEmpProperties}
                />

                <Route
                  exact
                  path="/corporate-departments/:id"
                  component={CorporateDepartments}
                />
                <Route
                  exact
                  path="/employeedetails/:id"
                  component={EmployeesDetails}
                />
                <Route exact path="/property" component={Property} />
                <Route
                  exact
                  path="/mapped-property"
                  component={MappedProperty}
                />
                <Route
                  exact
                  path="/mapped-property/details/:id"
                  component={MappedPropertyDetails}
                />
                <Route
                  exact
                  path="/mapped-property-details/:id"
                  component={MappedPropertyDetails}
                />

                <Route
                  exact
                  path="/seat-reservation/:id"
                  component={SeatReservation}
                />
                <Route exact path="/helpdesk" component={Helpdesk} />
                <Route
                  exact
                  path="/employee-reservation-list"
                  component={ReservationLogs}
                />
                <Route
                  exact
                  path="/employee-reservation-details/:id"
                  component={ReservationDetails}
                />
                <Route
                  exact
                  path="/upcoming/employee-reservation-details/:id"
                  component={ReservationDetails}
                />
                  <Route
                  exact
                  path="/history/employee-reservation-details/:id"
                  component={ReservationDetails}
                />
                <Redirect exact to="/corporate/dashboard" />
              </Switch>
            ) : (
              <Switch>
                <Route
                  exact
                  path="/user-pages/employee/login"
                  component={Login}
                />
                <Route exact path="/forgot-password" component={Forgot} />

                <Route
                  exact
                  path="/reservation-history"
                  component={SeatReservationHistory}
                />
                <Route
                  exact
                  path="/seat-reservation/:id"
                  component={SeatReservation}
                />

                <Redirect exact to="/reservation-history" />
              </Switch>
            )}
          </>
        )}
      </Suspense>
    );
  }
}

export default AppRoutes;
