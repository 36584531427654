import axios from "axios";

// export const API_URL = "https://uatnew.berighthere.com/";
const API_URL = process.env.REACT_APP_API_URL;

export const api = {
  corporate: API_URL + "api/corporate",
};

let token_type = localStorage.getItem("token_type")
  ? localStorage.getItem("token_type")
  : "";
let token = localStorage.getItem("token") ? localStorage.getItem("token") : "";
let role_id = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : "";

const ServiceLayer = {
  async postMethod(URL, Method, Data) {
    let url = API_URL + URL;

    const response = await fetch(url, {
      method: Method,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer" + " " + token + " " + role_id.role_id,
      },
      body: JSON.stringify(Data),
    });
    if (!response.ok) {
      if (response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("token_type");

        window.location.href = "/user-pages/corporate/login";
      }
      return response;
    }
    return response;
  },

  async postMethodNotToken(URL, Method, Data) {
    let url = API_URL + URL;

    const response = await fetch(url, {
      method: Method,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: '2P2mfbGMap5GRtjFhqTP5V85McgPT75BtmkNuRAn',
      },
      body: JSON.stringify(Data),
    });
    if (!response.ok) {
      if (response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("token_type");

        window.location.href = "/user-pages/corporate/login";
      }
      return response;
    }
    return response;
  },

  async fileupload(URL, Data) {
    let url = API_URL + URL;

    const response = await axios.post(url, Data, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        Authorization: token_type + " " + token + " " + role_id.role_id,
      },
    });
    if (!response.ok) {
      if (response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("token_type");

        window.location.href = "/user-pages/corporate/login";
      }
      return response;
    }
    return response;
  },

  async postDevMethod(URL, Method, Data) {
    let url = API_URL + URL;

    const response = await fetch(url, {
      method: Method,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer" + " " + token + " " + role_id.role_id,
        // role_id: role_id.role_id
      },
      body: JSON.stringify(Data),
    });
    if (!response.ok) {
      if (response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("token_type");

        window.location.href = "/user-pages/corporate/login";
      }
      return response;
    }
    return response;
  },
  async postMethodToken(URL, Method, Data) {
    let url = API_URL + URL;

    const response = await fetch(url, {
      method: Method,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token_type + " " + token + " " + role_id.role_id,
        // role_id: role_id.role_id
      },
      body: JSON.stringify(Data),
    });
    if (!response.ok) {
      if (response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("token_type");

        window.location.href = "/user-pages/corporate/login";
      }
      return response;
    }
    return response;
  },

  async getMethod(URL) {
    let url = API_URL + URL;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer" + " " + token + " " + role_id.role_id,
        // role_id: role_id.role_id
      },
    });
    if (!response.ok) {
      if (response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("token_type");

        window.location.href = "/user-pages/corporate/login";
      }
      return response;
    }
    return response;
  },

  async getDEVMethod(URL) {
    let url = API_URL + URL;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer" + " " + token + " " + role_id.role_id,
        // role_id: role_id.role_id
      },
    });
    if (!response.ok) {
      if (response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("token_type");

        window.location.href = "/user-pages/corporate/login";
      }
      return response;
    }
    return response;
  },

  loginAPI(URL, Data) {
    let url = API_URL + URL;

    return axios.post(url, Data);
  },
  async getData(URL) {
    let url = API_URL + URL;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer" + " " + token + " " + role_id.role_id,
        // role_id: role_id.role_id
      },
    });
    if (!response.ok) {
      if (response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("token_type");

        window.location.href = "/user-pages/corporate/login";
      }
      return response;
    }
    return response;
  },
  async getAuthData(URL) {
    let url = API_URL + URL;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: "2P2mfbGMap5GRtjFhqTP5V85McgPT75BtmkNuRAn",
      },
    });
    if (!response.ok) {
      if (response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("token_type");

        window.location.href = "/user-pages/corporate/login";
      }
      return response;
    }
    return response;
  },

  async getDatabyToken(URL) {
    let url = API_URL + URL;
    const response = await fetch(url, {
      headers: {
        Authorization: token_type + " " + token + " " + role_id.role_id,
        // role_id: role_id.role_id
      },
    });
    if (!response.ok) {
      if (response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("token_type");

        window.location.href = "/user-pages/corporate/login";
      }
      return response;
    }
    return response;
  },
};

export default ServiceLayer;
