import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";

import { Button, Container, Tab, Tabs } from "react-bootstrap";
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  InputLabel,
} from "@mui/material";

import { Link } from "react-router-dom";
import API from "../API_Services/ServiceLayer";
import moment from "moment";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import Loading from "react-fullscreen-loading";
import XLSX from "xlsx";
import "@fontsource/montserrat";

export const TabNames = [
  {
    title: "By Employee",
    eventKey: "byEmployee",
    tabClassName: "btn btn-primary",
  },
  {
    title: "By Location",
    eventKey: "byLocation",
    tabClassName: "btn btn-primary",
  },
];

const ReservationLogs = () => {
  const [key, setKey] = useState("byEmployee");
  const [status, setStatus] = useState();
  const [loader, setLoader] = useState();
  const [details, setDetails] = useState();
  const [detailsLocation, setLocationDetails] = useState([]);
  const [excelData, setExcelData] = useState();
  const [wholedetails, setwholeDetails] = useState();
  const [date, setDate] = useState(null);
  const [arr, setArr] = useState();
  const [month, setMonth] = useState(false);
  const [monthValue, setMonthValue] = useState(0);
  const [current, setCurrent] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedNames, setSelectedNames] = useState([]);

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedNames(value);

    // Collect and process data directly in this function
    console.log("Selected Names:", value);

    // You can perform other operations with the selected data here
  };
  const downloadExcel = (data) => {
    console.log("selectedNames", selectedNames);
    console.log("data", data);

    const filteredData =
      selectedNames.length > 0
        ? data.filter((element) => selectedNames.includes(element.name))
        : data;

    let array = [];
    filteredData.forEach((element) => {
      console.log("element", element);
      element.check_in_out.forEach((elem) => {
        elem.property.forEach((ele) => {
          console.log("ele", ele);
          ele.Employee_name = element.name;
          ele.Employee_id = element.user_code;
          ele.Mobile_no = element.mobile_no ? element.mobile_no : "not given";
          ele.Email = element.email;
          ele.Location = ele.address1 && ele.address2;
          ele.Property = ele.name;
          ele.Date = elem.created_at;
          ele.Check_in = elem.check_in_timestamp;
          ele.Check_out = elem.check_out_timestamp;
          ele.Hours = elem.hours;

          array.push(ele);

          delete ele.address1;
          delete ele.address2;
          delete ele.cctv_link;
          delete ele.cgst;
          delete ele.city_id;
          delete ele.contact_alternate_email;
          delete ele.contact_email;
          delete ele.contact_no;
          delete ele.contact_person;
          delete ele.country_code;
          delete ele.country_id;
          delete ele.created_at;
          delete ele.date;
          delete ele.city;
          delete ele.deleted_at;
          delete ele.end_at;
          delete ele.floor_file;
          delete ele.floor_no;
          delete ele.gst_registration_num;
          delete ele.id;
          delete ele.invoice_template_id;
          delete ele.is_alternative_primary;
          delete ele.is_cancel;
          delete ele.is_cancellation_policy;
          delete ele.is_draft;
          delete ele.is_primary;
          delete ele.is_qr_code;
          delete ele.is_verified;
          delete ele.landline;
          delete ele.landmark;
          delete ele.latitude;
          delete ele.locality;
          delete ele.long_desc;
          delete ele.longitude;
          delete ele.property;
          delete ele.property_code;
          delete ele.property_tabs;
          delete ele.property_type_id;
          delete ele.provider_id;
          delete ele.qr_code_path;
          delete ele.service_location_id;
          delete ele.sgst;
          delete ele.short_desc;
          delete ele.show_actual_name;
          delete ele.skip_plans;
          delete ele.sort_id;
          delete ele.start_at;
          delete ele.state_id;
          delete ele.status;
          delete ele.total_sq_ft;
          delete ele.updated_at;
          delete ele.website;
          delete ele.zipcode;
          delete ele.pseudoname;
          delete ele.is_available;
          delete ele.is_fit;
          delete ele.furnished_status;
          // delete ele.hours;
          // delete ele.email;
          // delete ele.mobile_no;
        });
      });
    });

    console.log("Array", array);

    const worksheet1 = XLSX.utils.json_to_sheet(array);

    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet1, "Employee Reports");

    XLSX.writeFile(workbook, "EmployeeReports.xlsx");
  };

  const downloadExcelLocation = (data) => {
    console.log("dataLoocation", data);
    let array = [];
    data.forEach((element) => {
      console.log("element", element);
      element.property.forEach((elem) => {
        console.log("elem", elem);
        elem.check_date.forEach((ele) => {
          console.log("ele", ele);
          ele.check_in_out.forEach((el) => {
            console.log("el", el);
            el.hours = el.hours;

            el.property_name = elem.property_name;
            el.location = element.name;

            el.employee_name = el && el.user && el.user.name;
            el.employee_id = el && el.user && el.user.user_code;
            el.mobile_no = elem.mobile_no ? elem.mobile_no : "not given";
            el.email = elem.email;
            el.date = el.checkin_date;
            el.check_in = el.check_in_timestamp;
            el.check_out = el.check_out_timestamp;

            delete el.address2;
            delete el.cctv_link;
            delete el.address1;
            delete el.cgst;
            delete el.city_id;
            delete el.contact_alternate_email;
            delete el.contact_email;
            delete el.contact_no;
            delete el.contact_person;
            delete el.country_code;
            delete el.country_id;
            delete el.created_at;
            delete el.date;
            delete el.city;
            delete el.deleted_at;
            delete el.end_at;
            delete el.floor_file;
            delete el.floor_no;
            delete el.gst_registration_num;
            delete el.id;
            delete el.invoice_template_id;
            delete el.is_alternative_primary;
            delete el.is_cancel;
            delete el.is_cancellation_policy;
            delete el.is_draft;
            delete el.is_primary;
            delete el.is_qr_code;
            delete el.is_verified;
            delete el.landline;
            delete el.landmark;
            delete el.latitude;
            delete el.locality;
            delete el.long_desc;
            delete el.longitude;
            delete el.property;
            delete el.property_code;
            delete el.property_tabs;
            delete el.property_type_id;
            delete el.provider_id;
            delete el.qr_code_path;
            delete el.service_location_id;
            delete el.sgst;
            delete el.short_desc;
            delete el.show_actual_name;
            delete el.skip_plans;
            delete el.sort_id;
            delete el.start_at;
            delete el.state_id;
            delete el.status;
            delete el.total_sq_ft;
            delete el.updated_at;
            delete el.website;
            delete el.zipcode;
            delete el.booking_id;
            delete el.check_in_status;
            delete el.check_out_status;
            delete el.property_id;
            delete el.user_id;
            delete el.user;
            delete el.check_in_timestamp;
            delete el.check_out_timestamp;

            array.push(el);
          });
        });
      });
    });

    console.log("mmmm", array);
    let dataA = array.map((m, i) => {
      return {
        PropertyName: m.property_name,
        Location: m.location,
        EmployeeName: m.employee_name,
        EmployeeCode: m.employee_id,
        EmployeeEmail: m.email,
        EmployeeMobile: m.mobile_no,
        CheckIn: m.check_in,
        CheckOut: m.check_out,
        Hours: m.hours,
      };
    });
    console.log("DATAARRAY", dataA);

    const worksheet1 = XLSX.utils.json_to_sheet(dataA);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet1, "Location Reports");
    XLSX.writeFile(workbook, "LocationReports.xlsx");
  };

  const columns = [
    {
      title: "Employee",
      field: "employee_name",
      customFilterAndSearch: (term, rowData) =>
        rowData.name.toLowerCase().indexOf(term.toLowerCase()) > -1,
      render: (rowData) => rowData.name,
    },
  ];

  const columnsLocation = [
    {
      title: "Location",
      field: "location",
      customFilterAndSearch: (term, rowData) =>
        rowData.name.toLowerCase().indexOf(term.toLowerCase()) > -1,
      render: (rowData) => rowData.name,
    },
  ];

  const checkColumns = [
    {
      title: "Date",
      field: "date",
      render: (data) => {
        return moment(data.created_at).format("DD-MMM-YYYY");
      },
    },
    {
      title: "Employee Id",
      field: "employe_id",
      render: (data) => {
        let empId = details.map((detail) => {
          if (detail.id == data.user_id) {
            return detail.user_code;
          }
        });
        return empId;
      },
    },
    {
      title: "Property Name",
      field: "property name",
      render: (data) => {
        return data.property.map((prop, index) => prop.property_name);
      },
    },
    {
      title: "Location",
      field: "location",
      render: (data) => {
        let properties = data.property.map((prop, index) => prop);
        let proprtyDetail = properties.map((property) => {
          return property.city;
        });
        let locationName = proprtyDetail.map((detail) => detail.name);
        return locationName;
      },
    },

    {
      title: "Check In",
      field: "check in",
      render: (data) => {
        let checkOut =
          data.check_in_timestamp && data.check_in_timestamp.split(" ");

        return (checkOut =
          checkOut && checkOut[1].slice(0, 5)
            ? (checkOut = checkOut && checkOut[1].slice(0, 5))
            : "NA");
      },
    },
    {
      title: "Check Out",
      field: "check out",
      render: (data, i) => {
        let checkOut =
          data.check_out_timestamp && data.check_out_timestamp.split(" ");
        let checkingDetails =
          data.check_out_status === 1 && data.is_auto_check_out === 1;
        checkOut =
          checkOut && checkOut[1].slice(0, 5)
            ? (checkOut = checkOut && checkOut[1].slice(0, 5))
            : "NA";

        return (
          <div key={i}>
            {checkOut}
            {checkingDetails && (
              <small className="text-danger text-nowrap">
                {"(System Checkout)"}
              </small>
            )}
          </div>
        );
      },
    },
    {
      title: "Hrs-Mm",
      field: "hrs",
      render: (data) => {
        return data.hours;
      },
    },
  ];
  const checkLocationColumns = [
    {
      title: "Property mns",
      field: "property name",
      render: (data) => {
        return data.property_name;
      },
    },
    {
      title: "Date",
      field: "date",
      render: (data) => {
        return data.check_date.map((date, index) => (
          <p key={index}>{date.checkin_date}</p>
        ));
      },
    },
    {
      title: "Employee Id",
      field: "employee_id",
      render: (data) => {
        return data.check_date.map((emp, ind) => {
          return emp.check_in_out.map((d, i) => {
            return <p key={i}>{d.user.user_code}</p>;
          });
        });
      },
    },
    {
      title: "Employee Name",
      field: "employee_name",
      render: (data) => {
        return data.check_date.map((emp, ind) => {
          return emp.check_in_out.map((d, i) => {
            return <p key={i}>{d.user.name}</p>;
          });
        });
      },
    },
    {
      title: "Check In",
      field: "check in",
      render: (data) => {
        return (
          data &&
          data.check_date.map((emp, ind) => {
            return emp.check_in_out.map((d, i) => {
              return (
                <p key={i}>{moment(d.check_in_timestamp).format("HH:mm")}</p>
              );
            });
          })
        );
      },
    },
    {
      title: "Check Out",
      field: "check_out",
      render: (data) => {
        return (
          data &&
          data.check_date.map((emp, ind) => {
            return emp.check_in_out.map((d, i) => {
              let checkingDetails =
                d.check_out_status === 1 && d.is_auto_check_out === 1;
              return (
                <p key={i}>
                  {moment(d.check_out_timestamp).format("HH:mm")}{" "}
                  {checkingDetails && (
                    <small className="text-danger text-nowrap">
                      {"(System Checkout)"}
                    </small>
                  )}
                </p>
              );
            });
          })
        );
      },
    },
    {
      title: "Hrs-Mm",
      field: "hrs",
      render: (data) => {
        return (
          data &&
          data.check_date.map((emp, ind) => {
            return emp.check_in_out.map((d, i) => {
              return <p key={i}>{d.hours}</p>;
            });
          })
        );
      },
    },
  ];

  const onTabChange = (tabs) => {
    const kk = tabs;
    // fetch(kk);
    setKey(kk);
    setStatus("");
    setDate(null);
  };

  const datechange = (e) => {
    if (e && e.length !== 0 && !!wholedetails) {
      console.log(e[0]);
      console.log(e[1]);
      setStartDate(e[0]);
      setEndDate(e[1]);

      API.postMethod(
        key === "byEmployee"
          ? "api/employee-check-in-out-report"
          : "api/property-check-in-out-report",
        "POST",
        {
          from_date: e[0],
          to_date: e[1],
          corporate_id: localStorage.getItem("corporate_id"),
        }
      )
        .then((res) => res.json())
        .then((result) => {
          setDetails(result.results);
        });
    } else {
      setDetails(wholedetails);
    }
  };

  useEffect(() => {
    console.log(month);
  }, [month]);

  const searchStatus = (e) => {
    let value = e.target.value;
    let id = localStorage.getItem("corporate_id");
    setCurrent(Number(value));
    setMonth(false);
    if (value == 3) {
      setMonth(true);
    }

    key === "byEmployee"
      ? API.postMethod("api/employee-check-in-out-report", "POST", {
          // from_date: startDate,
          // to_date: endDate,
          corporate_id: localStorage.getItem("corporate_id"),
          current: Number(value),
          month: value == 3 ? monthValue : null,
        })
          .then((res) => res.json())
          .then((result) => {
            setDetails(result.results);
          })
      : API.postMethod("api/property-check-in-out-report", "POST", {
          // from_date: e[0],
          // to_date: e[1],
          corporate_id: localStorage.getItem("corporate_id"),
          current: Number(value),
          month: value == 3 ? monthValue : null,
        })
          .then((res) => res.json())
          .then((result) => {
            setDetails(result.results);
          });

    console.log("SelectedVAlue", value);
  };
  const searchMonth = (e) => {
    let value = e.target.value;
    let id = localStorage.getItem("corporate_id");
    setMonthValue(value);
    API.postMethod("api/employee-check-in-out-report", "POST", {
      // from_date: startDate,
      // to_date: endDate,
      corporate_id: localStorage.getItem("corporate_id"),
      current: current,
      month: current == 3 ? Number(value) : null,
    })
      .then((res) => res.json())
      .then((result) => {
        setDetails(result.results);
      });

    console.log("selectedMonth", value);
  };

  useEffect(() => {
    setDetails([]);
    setLocationDetails([]);
    setExcelData([]);
    setLoader(true);
    const str =
      key == "byEmployee"
        ? "api/employee-check-in-out-report"
        : "api/property-check-in-out-report";

    setKey(key);
    API.postMethod(str, "POST", {
      corporate_id: localStorage.getItem("corporate_id"),
      //corporate_id: 40,
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("Data", result.results);
        setDetails(result.results);
        setLocationDetails(result.results);
        setwholeDetails(result.results);
        setLoader(false);
      });
  }, [key]);

  const ReservationDetailsTab = () => {
    return (
      <div>
        {/* dssdfds */}
        <MaterialTable
          options={{
            detailPanelType: "single",

            showTitle: false,
            search: true,
            paging: true,
            pageSize: 5,
            pageSizeOptions: [5],

            paginationPosition: "bottom",
            headerStyle: { position: "fixed", top: 0 },
            maxBodyHeight: 500,
          }}
          columns={columns}
          data={details || []}
          detailPanel={(rowData) => {
            console.log("rowData", rowData);
            return (
              <MaterialTable
                data={rowData.check_in_out}
                options={{
                  paging: true,
                  paginationPosition: "bottom",
                  pageSize: 5,
                  pageSizeOptions: [5],
                  search: false,
                  showTitle: false,
                  toolbar: false,
                  headerStyle: { position: "sticky", top: 0 },
                  maxBodyHeight: 500,
                }}
                columns={checkColumns}
              />
            );
          }}
          components={{
            OverlayLoading: (props) => (
              <div>
                {" "}
                <Loading loading={loader} />{" "}
              </div>
            ),
          }}
        />
      </div>
    );
  };

  const LocationDetailsTable = () => {
    return (
      <div className="childTbl">
        <MaterialTable
          options={{
            detailPanelType: "single",

            showTitle: false,
            search: true,
            paging: true,
            pageSize: 5,
            pageSizeOptions: [5],

            paginationPosition: "bottom",
            headerStyle: { position: "fixed", top: 0 },
            maxBodyHeight: 500,
          }}
          columns={columnsLocation}
          data={detailsLocation}
          detailPanel={(rowData) => {
            // console.log("vhsfkjasdlk", rowData);
            return (
              <MaterialTable
                data={rowData.property}
                options={{
                  paging: true,
                  paginationPosition: "bottom",
                  pageSize: 5,
                  pageSizeOptions: [5],
                  search: false,
                  showTitle: false,
                  toolbar: false,
                  headerStyle: { position: "sticky", top: 0 },
                  maxBodyHeight: 500,
                }}
                columns={checkLocationColumns}
              />
            );
          }}
          components={{
            OverlayLoading: (props) => (
              <div>
                {" "}
                <Loading loading={loader} />{" "}
              </div>
            ),
          }}
        />
      </div>
    );
  };

  return (
    <Container className="reservationtab">
      <div className="row">
        <div className="col-12 d-flex align-items-center justify-content-between flex-wrap">
          <h4 className="text-primary mb-0 font-weight-bold">
            {key === "byEmployee" ? (
              <h5>Consolidate Check In Check Out Report (by Employee) </h5>
            ) : (
              <h5>Consolidate Check In Check Out Report (by Location) </h5>
            )}
          </h4>
          <Button
            onClick={
              key === "byEmployee"
                ? () => downloadExcel(details)
                : () => downloadExcelLocation(detailsLocation)
            }>
            Export as Excel
          </Button>
        </div>
      </div>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => onTabChange(k)}>
        {TabNames.map((tab) => (
          <Tab
            className="tabpadd"
            eventKey={tab.eventKey}
            title={tab.title}
            tabClassName={tab.tabClassName}>
            {loader ? (
              <Loading loading={loader} />
            ) : (
              <>
                {tab.eventKey === "byEmployee" ? (
                  <div style={{ overflowY: "hidden" }}>
                    <div className="row dropdownform justify-content-between align-items-center">
                      <DateRangePicker
                        dayPlaceholder="dd"
                        monthPlaceholder="mm"
                        yearPlaceholder="yy"
                        onChange={(e) => {
                          setDate(e);
                          datechange(e);
                        }}
                        className="ml-4"
                        value={date}
                        style={{ width: "100%" }}
                      />
                      <div>
                        <select
                          name="search_status"
                          onChange={(e) => searchStatus(e)}
                          className=" ml-2 selectmob"
                          style={{ fontSize: "12px", padding: "12px" }}>
                          <option value={0} selected>
                            Select Current Day/Week/Month/Year
                          </option>
                          <option value={0}>All</option>
                          <option value={1}>Day</option>
                          <option value={2}>Week</option>
                          <option value={3}>Month</option>
                          <option value={4}>Year</option>
                        </select>
                      </div>
                      {month == true && (
                        <div>
                          <select
                            name="search_status"
                            onChange={(e) => searchMonth(e)}
                            className="ml-2 selectmob"
                            style={{ fontSize: "12px", padding: "12px" }}>
                            <option value={0} selected>
                              Select Month
                            </option>
                            <option value={1}>January</option>
                            <option value={2}>Feb</option>
                            <option value={3}>Mar</option>
                            <option value={4}>Apr</option>
                            <option value={5}>May</option>
                            <option value={6}>Jun</option>
                            <option value={7}>Jul</option>
                            <option value={8}>Aug</option>
                            <option value={9}>Sep</option>
                            <option value={10}>Oct</option>
                            <option value={11}>Nov</option>
                            <option value={12}>Dec</option>
                          </select>
                        </div>
                      )}
                      <FormControl
                        style={{
                          width: 300,
                          margin: 5,
                          padding: 0,
                        }}>
                        <Select
                          multiple
                          value={selectedNames}
                          onChange={handleChange}
                          displayEmpty
                          renderValue={(selected) => (
                            <div
                              style={{
                                fontSize: "12px",
                                fontFamily: "Montserrat, sans-serif",
                              }}>
                              {selected.length === 0 ? (
                                <span>Select names </span>
                              ) : (
                                selected.join(", ")
                              )}
                            </div>
                          )}
                          style={{
                            padding: "6px",
                            fontSize: "12px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            fontFamily: "Montserrat, sans-serif",
                          }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 300,
                                width: 300,
                              },
                            },
                          }}>
                          {/* Placeholder item */}
                          {selectedNames.length === 0 && (
                            <MenuItem disabled>
                              <span>Select names </span>
                            </MenuItem>
                          )}
                          {details && details.length > 0 ? (
                            details.map((option, index) => (
                              <MenuItem
                                key={index}
                                value={option.name}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "8px 16px",
                                  fontFamily: "Montserrat, sans-serif",
                                }}>
                                <Checkbox
                                  checked={
                                    selectedNames.indexOf(option.name) > -1
                                  }
                                />
                                <ListItemText primary={option.name} />
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem disabled>No options available</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </div>

                    <ReservationDetailsTab />
                  </div>
                ) : (
                  <div style={{ overflowY: "hidden" }}>
                    <LocationDetailsTable />
                  </div>
                )}
              </>
            )}
          </Tab>
        ))}
      </Tabs>
    </Container>
  );
};

export default ReservationLogs;
