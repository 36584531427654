import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse, Form, Modal } from "react-bootstrap";
import { Trans } from "react-i18next";
import Api from "../API_Services/ServiceLayer";

class SidebarPanel extends Component {
  state = {
    user: null,
    img: true,
    username: "",
    employee: "",
    issidebarexist: false,
  };

  componentDidMount() {
    let value = JSON.parse(localStorage.getItem("user"));

    const nav = document.querySelector("body");
    let bexisit = nav.classList.contains("sidebar-icon-only");

    if (bexisit) {
      this.setState({ issidebarexist: true });
    }
    if (value) this.setState({ username: value.name });
  }

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/basic-ui", state: "basicUiMenuOpen" },
      { path: "/advanced-ui", state: "advancedUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/maps", state: "mapsMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
      { path: "/general-pages", state: "generalPagesMenuOpen" },
      { path: "/ecommerce", state: "ecommercePagesMenuOpen" },
      { path: "/add-employee", state: "propertyMenuOpen" },
      { path: "/provider", state: "addProviderOpen" },
      { path: "/locations", state: "addLocationOpen" },
      { path: "/mapped-property", state: "employeeMenuOpen" },
      { path: "/property", state: "employeeMenuOpen" },
      { path: "/documents", state: "employeeMenuOpen" },
      { path: "/link-property", state: "propertyMenuOpen" },
      { path: "/employees", state: "propertyMenuOpen" },
      { path: "/helpdesk", state: "helpDeskMenuOpen" },
      { path: "/booking-list", state: "bookingMenuOpen" },
      { path: "/occupancy-report", state: "occupancyreport" },
      { path: "/employee-reservation-list", state: "reservationLogs" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  
  handleChangePassword() {
    this.setState({ show: true });
  }


  onCheckPassword(e) {
    let name = e.target.name;

    if (name == "new_password" && e.target.value == "") {
      this.setState({ passErr1: "Enter New Password" });
    }
    if (name == "confirm_password" && e.target.value == "") {
      this.setState({ passErr2: "Enter Confirm Password" });
    }

    if (this.state.password1 !== this.state.password2) {
      console.log("password1-ok", this.state.password1);
      console.log("password2-ok", this.state.password2);
      this.setState({ matchPssword: "Password Not Matched" });
    } else {
      console.log("password1-error", this.state.password1);
      console.log("password2-error", this.state.password2);
    }
  }

  successDone() {
    return localStorage.clear(), window.location.reload();
  }

  
  handleFormSubmit(e) {
    e.preventDefault();
    let username = "";
    let role = "";
    let id = "";
    if (localStorage.getItem("user")) {
      let user = JSON.parse(localStorage.getItem("user"));
      username = user["name"];
      role = user["role_id"];
      id = user["id"];
    }
    let data = {
      password: this.state.password1,
      user_id: id,
      role_id: role,
    };

    Api.postMethodToken("api/corporate_password_update", "post", data)
    .then((res) =>
      res.json().
    then((result) => {
        if (result.status == "success") {
          this.setState({ show: false });
          this.setState({ success: true });
        }
      })
    );
  }

  handleLogout(e) {
    e.preventDefault();
    const role_id = JSON.parse(localStorage.getItem("user")).role_id;
    localStorage.removeItem("user");
    localStorage.removeItem("corporate_id");
    localStorage.removeItem("token");

    let value = JSON.stringify("/corporate/dashboard");
    localStorage.setItem("path", value);

    console.log("role_id-->", role_id);

    if (role_id === 6) {
      window.location = "/user-pages/corporate/login";
    } else if (role_id === 7) {
      window.location = "/user-pages/employee/login";
    } else {
      window.location = "/";
    }
  }

  render() {
    let value = JSON.parse(localStorage.getItem("user"));
    let bexisit = false;
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="d-flex justify-content-between align-items-center flex-column h-100">
          <ul className="nav w-100">
            <li className="nav-item nav-profile">
              <a
                href="!#"
                className="nav-link"
                onClick={(evt) => evt.preventDefault()}
              >
                <div className="nav-profile-image position-relative">
                  <img
                    src={require("../../assets/images/faces/facef.png")}
                    alt="profile"
                  />
                  <span className="availability-statuss online"></span>
                </div>
                <div className="nav-profile-text ml-2">
                  <p
                    className="font-weight-bold mb-2"
                    style={{
                      width: "125px",
                      overflow: "hidden",
                      whiteSpace: "pre-line",
                    }}
                  >
                    {value && value.name}
                  </p>
                  <span className="text-secondary text-small">Corporate</span>
                </div>

                <button
                  className="navbar-toggler navbar-toggler p-0"
                  type="button"
                  onClick={() => {
                    document.body.classList.toggle("sidebar-icon-only");
                    const nav = document.querySelector("body");
                    bexisit = nav.classList.contains("sidebar-icon-only");
                    if (bexisit) {
                      this.setState({
                        issidebarexist: !this.state.issidebarexist,
                      });
                    }
                  }}
                >
                  {this.state.issidebarexist ? (
                    // <span className="mdi mdi-menu"></span>
                    <div class="hamburger-menu">
                      <span class="bar"></span>
                      <span class="bar"></span>
                      <span class="bar"></span>
                    </div>
                  ) : (
                    <div class="hamburger-menu">
                      <span class="bar"></span>
                      <span class="bar"></span>
                      <span class="bar"></span>
                    </div>
                    // <span className="mdi mdi-close"></span>
                  )}
                </button>
              </a>
            </li>

            <li
              className={
                this.isPathActive("/corporate/dashboard")
                  ? "nav-item   active"
                  : "nav-item"
              }
            >
              <Link className="nav-link" to="/corporate/dashboard">
                <i className="mdi mdi-view-dashboard menu-icon"></i>
                <span className="menu-title">
                  <Trans>Dashboard</Trans>
                </span>
              </Link>
            </li>

            <li
              className={
                this.isPathActive("/mapped-property") ||
                this.isPathActive("/property")
                  ? "nav-item submenu active"
                  : "nav-item submenu"
              }
            >
              <div
                className={
                  this.state.employeeMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("employeeMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-domain menu-icon"></i>
                <span className="menu-title">
                  <Trans>Properties</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.employeeMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    <Link
                      className={
                        this.isPathActive("/mapped-property")
                          ? "nav-link active font-weight-bold"
                          : "nav-link"
                      }
                      to="/mapped-property"
                    >
                      <Trans>Mapped Properties</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        this.isPathActive("/documents")
                          ? "nav-link active font-weight-bold"
                          : "nav-link"
                      }
                      to="/documents"
                    >
                      <Trans>Uploaded Documents</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        this.isPathActive("/property")
                          ? "nav-link active font-weight-bold"
                          : "nav-link"
                      }
                      to="/property"
                    >
                      <Trans>Property List</Trans>
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>

            <li
              className={
                this.isPathActive("/employees") ||
                this.isPathActive("/add-employee") ||
                this.isPathActive("/link-property")
                  ? "nav-item submenu active"
                  : "nav-item submenu"
              }
            >
              <div
                className={
                  this.state.propertyMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("propertyMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-account-multiple menu-icon"></i>
                <span className="menu-title">
                  <Trans>Employees</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.propertyMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    <Link
                      className={
                        this.isPathActive("/employees")
                          ? "nav-link active font-weight-bold"
                          : "nav-link"
                      }
                      to="/employees"
                    >
                      <Trans>Employee List</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        this.isPathActive("/add-employee")
                          ? "nav-link active font-weight-bold"
                          : "nav-link"
                      }
                      to="/add-employee"
                    >
                      <Trans>Onboard Employee</Trans>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      className={
                        this.isPathActive("/link-property")
                          ? "nav-link active font-weight-bold"
                          : "nav-link"
                      }
                      to="/link-property"
                    >
                      <Trans>Link Employees</Trans>
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>

            {/* <li
              className={
                this.isPathActive("/employee-reservation-list")
                  ? "nav-item submenu active"
                  : "nav-item submenu"
              }
            >
              <div
                className={
                  this.state.reservationLogs
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("reservationLogs")}
                data-toggle="collapse"
              >
                <i className="mdi mdi mdi-alert-outline menu-icon"></i>

                <span className="menu-title">
                  <Trans>Reservations List</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.reservationLogs}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    <Link
                      className={
                        this.isPathActive("/employee-reservation-list")
                          ? "nav-link active font-weight-bold"
                          : "nav-link"
                      }
                      to="/employee-reservation-list"
                    >
                      <Trans>Reservation List</Trans>
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li> */}

            <li
              className={
                this.isPathActive("/employee-reservation-list")
                  ? "nav-item   active"
                  : "nav-item"
              }
            >
              <Link className="nav-link" to="/employee-reservation-list">
                <i className="mdi mdi-post-outline  menu-icon"></i>
                <span className="menu-title">
                  <Trans>Reservation List</Trans>
                </span>
              </Link>
            </li>

            <li
              className={
                this.isPathActive("/helpdesk")
                  ? "nav-item submenu active"
                  : "nav-item submenu"
              }
            >
              <div
                className={
                  this.state.helpDeskMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("helpDeskMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi mdi-help-box menu-icon"></i>

                <span className="menu-title">
                  <Trans>Helpdesk</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.helpDeskMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    <Link
                      className={
                        this.isPathActive("/helpdesk")
                          ? "nav-link active font-weight-bold"
                          : "nav-link"
                      }
                      to="/helpdesk"
                    >
                      <Trans>Issues List</Trans>
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>

            <li
              className={
                this.isPathActive("/booking-list")
                  ? "nav-item submenu active"
                  : "nav-item submenu"
              }
            >
              <div
                className={
                  this.state.bookingMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("bookingMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi mdi-filter-variant menu-icon"></i>

                <span className="menu-title">
                  <Trans>Booking Manangement</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.bookingMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    <Link
                      className={
                        this.isPathActive("/booking-list")
                          ? "nav-link active font-weight-bold"
                          : "nav-link"
                      }
                      to="/booking-list"
                    >
                      <Trans>Booking List</Trans>
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>

            <li
              className={
                this.state.zettingMenuOpen
                  ? "nav-item submenu active"
                  : "nav-item submenu"
              }
            >
              <div
                className={
                  this.state.zettingMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("zettingMenuOpen")}
                data-toggle="collapse"
              >
                {/* <i className="mdi mdi mdi-settings menu-icon"></i> */}
                <i className="mdi mdi-settings menu-icon"></i>

                <span className="menu-title">
                  <Trans>Settings</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.zettingMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"   onClick={(event) => this.handleChangePassword()} >
                  <div
                      className={
                        this.state.zettingMenuOpen
                          ? "nav-link active font-weight-bold"
                          : "nav-link"
                      }
                      onClick={(event) => this.handleChangePassword()}
                      // to="/booking-list"

                    >
                      <Trans>Change Password</Trans>
                    </div>
                  </li>
                </ul>
              </Collapse>
            </li>

            {/* <li
              className={
                this.isPathActive("/occupancy-report")
                  ? "nav-item submenu active"
                  : "nav-item submenu"
              } >
              <div
                className={
                  this.state.occupancyreport
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("occupancyreport")}
                data-toggle="collapse"
              >
                <i className="mdi mdi mdi-finance menu-icon"></i>

                <span className="menu-title">
                  <Trans>Reports</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.occupancyreport}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    <Link
                      className={
                        this.isPathActive("/occupancy-report")
                          ? "nav-link active font-weight-bold"
                          : "nav-link"
                      }
                      to="/occupancy-report"
                    >
                      <Trans>Occupancy Report</Trans>
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li> */}
          </ul>

          <div className="w-100">
            <ul className="nav w-100 pb-0">
              <li className="nav-item">
                <Link
                  className="nav-link align-items-baseline"
                  onClick={this.handleLogout}
                >
                  <i className="mdi mdi-login-variant menu-icon"></i>
                  <span className="menu-title">
                    <Trans>
                      {" "}
                      <b>Signout</b>
                    </Trans>
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <Modal
          backdrop="static"
          show={this.state.show}
          onHide={() =>
            this.setState({
              show: false,
              passErr1: "",
              passErr2: "",
              password1: "",

              password2: "",
              matchPssword: "",
            })
          }
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Change Password
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <form onSubmit={(e) => this.handleFormSubmit(e)}>
              <Form.Group>
                <label>
                  New Password
                  <span className="text-danger">*</span>
                </label>
                <Form.Control
                  type="password"
                  minLength={6}
                  maxLength={55}
                  name="new_password"
                  // onBlur={(e) => this.onCheckPassword(e)}
                  onFocus={() =>
                    this.setState({ passErr1: "", matchPssword: "" })
                  }
                  value={this.state.new_password}
                  onChange={(e) => this.setState({ password1: e.target.value })}
                  placeholder="Enter New Password"
                  required
                  size="sm"
                />
                {this.state.passErr1 ? (
                  <span className="text-danger text-small">
                    {this.state.passErr1}
                  </span>
                ) : (
                  ""
                )}
              </Form.Group>
              <Form.Group>
                <label>
                  Confirm New Password
                  <span className="text-danger">*</span>
                </label>
                <Form.Control
                  type="password"
                  minLength={6}
                  maxLength={55}
                  onFocus={() =>
                    this.setState({ passErr2: "", matchPssword: "" })
                  }
                  onBlur={(e) => this.onCheckPassword(e)}
                  value={this.state.confirm_password}
                  name="confirm_password"
                  onChange={(e) => this.setState({ password2: e.target.value })}
                  placeholder="Confirm Password"
                  required
                  size="sm"
                />
                {this.state.passErr2 ? (
                  <span className="text-danger text-small">
                    {this.state.passErr2}
                  </span>
                ) : this.state.matchPssword ? (
                  <span className="text-danger text-small">
                    {this.state.matchPssword}
                  </span>
                ) : (
                  ""
                )}
              </Form.Group>

              <div className="d-flex flex-wrap justify-content-end">
                <button
                  type="reset"
                  onClick={() =>
                    this.setState({
                      show: false,
                    })
                  }
                  className="btn btn-light rounded-0"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary mr-2 rounded-0"
                >
                  Update
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <Modal
          centered
          backdrop="static"
          show={this.state.success}
          onHide={() => this.successDone()}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header>
            <Modal.Title id="example-custom-modal-styling-title"></Modal.Title>
            <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
              <h4 className="text-center">
                Your password updated successfully!
              </h4>
              <button
                type="button"
                className="btn btn-sm btn-primary rounded-0 mt-3 w-50"
                onClick={() => this.successDone()}
              >
                Close
              </button>
            </Modal.Body>
          </Modal.Header>
        </Modal>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  changesidebar() {
    document.querySelector("#sidebar");
    console.log(document.querySelector("#sidebar"));
    console.log(
      document.querySelector("#sidebar").classList.contains("sidebaractive")
    );
    let bool = document
      .querySelector("#sidebar")
      .classList.contains("sidebaractive");
    if (!bool) {
      document.querySelector("#sidebar").classList.add("sidebaractive");
      document.querySelector(".main-panel").classList.toggle("mainactive");
    } else {
      document.querySelector("#sidebar").classList.remove("sidebaractive");
      document.querySelector(".main-panel").classList.remove("mainactive");
    }
  }
}

export default withRouter(SidebarPanel);
