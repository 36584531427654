import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './Shared/Navbar';
import SettingsPanel from './Shared/SettingsPanel';
import SidebarEmployee from './Shared/SidebarEmployee';
import SidebarPanel from './Shared/SidebarPanel';

class App extends Component {

  state = {};

  constructor(props) {
    super(props);

    this.state = { logginStatus: true };
    this.events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress"
    ];

    this.logout = this.logout.bind(this);
    this.resetTimeout = this.resetTimeout.bind(this);

    for (var i in this.events) {
      window.addEventListener(this.events[i], this.resetTimeout);
    }

    this.setTimeout();
  }

  clearTimeout() {
    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  }

  setTimeout() {
    this.logoutTimeout = setTimeout(this.logout, 40 * 30 * 1000);
  }

  logout() {
    window.location.href = "/user-pages/corporate/login"
    localStorage.removeItem("user");
    localStorage.removeItem("corporate_id");
    localStorage.removeItem("token_type");
    localStorage.removeItem("token");
    let value = JSON.stringify( "/corporate/dashboard")
    localStorage.setItem("path", value);

    this.setState({ logginStatus: false });
  }

  resetTimeout() {
    this.clearTimeout();
    this.setTimeout();
  }

  componentDidMount() {
    this.onRouteChanged();

  }

  render() {
    let navbarComponent = !this.state.isFullPageLayout ? (
      !this.state.isUserpage ? (
        this.state.usercheck ? (
          <Navbar />
        ) : (
          <Navbar />
        )
      ) : (
        <Navbar />
      )
    ) : (
      ''
    );

    const userData = JSON.parse(localStorage.getItem('user'));
    let sidebarComponent = '';
    if (!this.state.isFullPageLayout && !this.state.isUserpage) {
      if (userData) {
        if (userData.role_id == 6) {
          sidebarComponent = <SidebarPanel />;
        } else if (userData.role_id == 7) {
          sidebarComponent = <SidebarEmployee />;
        } else {
          sidebarComponent = <SidebarPanel />;
        }
      } else {
        sidebarComponent = <SidebarPanel />;
      }
    }

    let SettingsPanelComponent = '';
    if (!this.state.isFullPageLayout && !this.state.isUserpage) {
      SettingsPanelComponent = <SettingsPanel />;
    }

    return (
      <div className="container-scroller">
        {navbarComponent}
        <div className="container-fluid page-body-wrapper">
          {sidebarComponent}
          <div className="main-panel">
            <div className="content-wrapper">
              <AppRoutes />
              {SettingsPanelComponent}
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {


    console.log('ROUTE CHANGED');
    const { i18n } = this.props;
    const body = document.querySelector('body');

    const { location } = this.props;
    const { pathname } = location;

    if (pathname !== "/user-pages/corporate/login") {
      let routevalue = JSON.stringify(pathname);
      localStorage.setItem("path", routevalue);
    }

    if (pathname === '/layout/RtlLayout') {
      body.classList.add('rtl');
      i18n.changeLanguage('ar');
    } else {
      body.classList.remove('rtl');
      i18n.changeLanguage('en');
    }
    window.scrollTo(0, 0);

    const bodyWrapper = document.querySelector('.page-body-wrapper');
    const scrollContainer = document.querySelector('.container-scroller');

    // fullpage view
    const fullPageLayoutRoutes = [
      '/user-pages/login',
      '/user-pages/corporate/login',
      '/user-pages/employee/login',
      '/user-pages/register',
      '/user-pages/register-1',
      '/user-pages/lockscreen',
      '/error-pages/error-404',
      '/error-pages/error-500',
      '/general-pages/landing-page',
      '/forgot-password',
      '/reset-password'
    ];

    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (pathname === fullPageLayoutRoutes[i]) {
        this.setState({
          isFullPageLayout: true,
        });
        bodyWrapper.classList.add('full-page-wrapper');
        scrollContainer.classList.remove('user-view');
        scrollContainer.classList.remove('booking-view');
        break;
      } else {
        this.setState({
          isFullPageLayout: false,
        });
        bodyWrapper.classList.remove('full-page-wrapper');
        scrollContainer.classList.add('user-view');
        scrollContainer.classList.add('booking-view');
      }
    }

    // with header and footer view
    if (pathname.includes('/discover')) {
      this.setState({
        isUserpage: true,
      });
      scrollContainer.classList.remove('booking-view');
    } else {
      this.setState({
        isUserpage: false,
      });
    }

    // with header, footer & sidebar view
    const userRoutes = [
      '/upcoming-bookings',
      '/booking-history',
      '/booking-details',
      '/reservation-history'
    ];

    for (let i = 0; i < userRoutes.length; i++) {
      if (pathname.includes(userRoutes[i])) {
        this.setState({
          usercheck: true,
        });
        break;
      } else {
        this.setState({
          usercheck: false,
        });
      }
    }

    // if (!userData) {
    //   window.location = "/user-pages/corporate/login";
    // }
  }

}

export default withTranslation()(withRouter(App));
