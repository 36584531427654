import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';

class SidebarEmployee extends Component {
  state = {
    user: null,
    img: true,
  };

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: '/apps', state: 'appsMenuOpen' },
      { path: '/basic-ui', state: 'basicUiMenuOpen' },
      { path: '/advanced-ui', state: 'advancedUiMenuOpen' },
      { path: '/form-elements', state: 'formElementsMenuOpen' },
      { path: '/tables', state: 'tablesMenuOpen' },
      { path: '/maps', state: 'mapsMenuOpen' },
      { path: '/icons', state: 'iconsMenuOpen' },
      { path: '/charts', state: 'chartsMenuOpen' },
      { path: '/user-pages', state: 'userPagesMenuOpen' },
      { path: '/error-pages', state: 'errorPagesMenuOpen' },
      { path: '/general-pages', state: 'generalPagesMenuOpen' },
      { path: '/ecommerce', state: 'ecommercePagesMenuOpen' },
      { path: '/property', state: 'addPropertyOpen' },
      { path: '/provider', state: 'addProviderOpen' },
      { path: '/locations', state: 'addLocationOpen' },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  render() {
    return (
      <div>
        <div className="sidebarline hamburger-wrapper" onClick={() => this.changesidebar()}>
          <div className="hamburger">
            <span />
            <span />
            <span />
          </div>
        </div>

        <nav className="sidebar sidebar-offcanvas corp-nav-bar" id="sidebar">
          <ul className="nav"> 
             <li
              className={
                this.isPathActive('/reservation-history') ? 'nav-item mt-4 active' : 'mt-4 nav-item'
              }
            >
              <Link className="nav-link" to="/reservation-history">
                <span className="menu-title">
                  <Trans>Reservation History</Trans>
                </span>
                <i className="mdi mdi-city menu-icon"></i>
              </Link>
            </li>
  
          </ul>
        </nav>
      </div>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  changesidebar() {
    document.querySelector('#sidebar')
    console.log(document.querySelector('#sidebar'));
    console.log(document.querySelector('#sidebar').classList.contains("sidebaractive"))
    let bool = document.querySelector('#sidebar').classList.contains("sidebaractive");
    if (!bool) {
      document.querySelector('#sidebar').classList.add('sidebaractive');
      document.querySelector('.main-panel').classList.toggle('mainactive');
    } else {
      document.querySelector('#sidebar').classList.remove('sidebaractive');
      document.querySelector('.main-panel').classList.remove('mainactive');
    }
  }

  componentDidMount() {
    this.onRouteChanged();
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }
}

export default withRouter(SidebarEmployee);
